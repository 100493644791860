import constRouter from '@/constants/constRouter';
import permissionCode from '@/constants/permissionCode';

export default [
  {
    title: 'System',
    icon: 'CpuIcon',
    children: [
      {
        title: 'Staff',
        icon: 'UsersIcon',
        route: { name: constRouter.USER_STAFF.name },
        action: permissionCode.STAFF_LIST.action,
        resource: permissionCode.STAFF_LIST.resource,
      },
      {
        title: 'Permission',
        icon: 'LinkIcon',
        route: { name: constRouter.SYSTEM_AUTHENTICATION.name },
        action: permissionCode.PERMISSION_LIST.action,
        resource: permissionCode.PERMISSION_LIST.resource,
      },
      {
        title: 'Appraisal question',
        icon: 'PenToolIcon',
        route: { name: constRouter.SYSTEM_QUESTION.name },
        action: permissionCode.QUESTION_LIST.action,
        resource: permissionCode.QUESTION_LIST.resource,
      },
      {
        title: 'System log',
        icon: 'CodepenIcon',
        route: { name: constRouter.SYSTEM_HISTORY_LOG.name },
      },
    ],
  },
];
