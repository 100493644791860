import constRouter from '@/constants/constRouter';
import permissionCode from '@/constants/permissionCode';

export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Auth',
    route: { name: constRouter.DASHBOARD.name },
  },
];
