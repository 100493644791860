import constRouter from '@/constants/constRouter';
import permissionCode from '@/constants/permissionCode';

export default [
  {
    title: 'Customer',
    icon: 'UserIcon',
    action: permissionCode.CUSTOMER_LIST.action,
    resource: permissionCode.CUSTOMER_LIST.resource,
    route: { name: constRouter.CUSTOMER.name },
  },
];
