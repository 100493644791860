import constRouter from '@/constants/constRouter';
import permissionCode from '@/constants/permissionCode';

export default [
  {
    title: 'iLương',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Contract',
        icon: 'FileTextIcon',
        action: permissionCode.PAYROLL_LIST.action,
        resource: permissionCode.PAYROLL_LIST.resource,
        route: { name: constRouter.PAYROLL_LIST.name },
      },
      {
        title: 'Payment',
        icon: 'DollarSignIcon',
        action: permissionCode.PAYROLL_PAYMENT_LIST.action,
        resource: permissionCode.PAYROLL_PAYMENT_LIST.resource,
        route: { name: constRouter.PAYROLL_PAYMENT_LIST.name },
      },
      {
        title: 'Company',
        icon: 'GlobeIcon',
        action: permissionCode.PAYROLL_COMPANY_LIST.action,
        resource: permissionCode.PAYROLL_COMPANY_LIST.resource,
        route: { name: constRouter.PAYROLL_COMPANY_LIST.name },
      },
    ],
  },
];
