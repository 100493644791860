import constRouter from '@/constants/constRouter';
import permissionCode from '@/constants/permissionCode';

export default [
  {
    title: 'Contract',
    icon: 'ClipboardIcon',
    action: permissionCode.CONTRACT_LIST.action,
    resource: permissionCode.CONTRACT_LIST.resource,
    route: { name: constRouter.CONTRACT.name },
  },
];
