import constRouter from '@/constants/constRouter';

export default [
  {
    title: 'Investment',
    icon: 'BarChartIcon',
    // action: permissionCode.CONTRACT_LIST.action,
    // resource: permissionCode.CONTRACT_LIST.resource,
    children: [
      {
        title: 'Investment list',
        icon: 'ListIcon',
        route: { name: constRouter.INVESTMENT.name },
      },
      {
        title: 'Withdraw list',
        icon: 'CornerDownLeftIcon',
        route: { name: constRouter.WITHDRAW.name },
      },
    ],
  },
];
